import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { ICardProps } from './Card';

const bgVariants = {
    primary: colors.brand.main,
    secondary: colors.brand.medium,
};

export const StyledCard = styled.div<ICardProps>`
    border-radius: ${({ borderRadius }) => (borderRadius ? `${borderRadius}px` : '27px')};
    background-color: ${({ variants = 'primary', backgroundColor }) =>
        variants ? bgVariants[variants] : backgroundColor};
    position: relative;
`;

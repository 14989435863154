import React from 'react';
import { useRouter } from 'next/router';
import { imageLinks } from '../../data/imageLinks';
import {
    BannerBtnWrap,
    BannerButton,
    BannerContent,
    BannerContentSeparator,
    BannerContentWrap,
    BannerRightContent,
    BannerText,
    BannerWrapper,
    InnerContent,
    InnerTitle,
    MainTitle,
} from './banner.style';
import useTranslation from 'next-translate/useTranslation';

const Banner = () => {
    const { banner, mobileBanner } = imageLinks;
    const { t, lang } = useTranslation('common');
    const router = useRouter();

    const redirectToProduct = (e) => {
        e.preventDefault();
        router.push('/products').then(() => {});
    };

    return (
        <BannerWrapper mobileImage={mobileBanner.link} image={banner.link}>
            <BannerContentWrap>
                <BannerContent>
                    <MainTitle>츄 초이스</MainTitle>
                    <InnerContent>
                        <BannerContentSeparator separator />
                        <InnerTitle>It’s Chu Choice.</InnerTitle>
                    </InnerContent>
                    <InnerContent>
                        <BannerContentSeparator />
                        <BannerRightContent>
                            <BannerText>{t('banner.line1')}</BannerText>
                            <BannerText>{t('banner.line2')}</BannerText>
                            <BannerText>{t('banner.line3')}</BannerText>
                            {lang === 'th' && (
                                <BannerText>
                                    {t('banner.line4')}
                                    <span>&nbsp;{t('banner.highlightedText')}</span>
                                </BannerText>
                            )}
                        </BannerRightContent>
                    </InnerContent>
                </BannerContent>
                <BannerBtnWrap onClick={redirectToProduct}>
                    <BannerButton variants="secondary">{t('banner.buttonText')}</BannerButton>
                </BannerBtnWrap>
            </BannerContentWrap>
        </BannerWrapper>
    );
};

export default Banner;

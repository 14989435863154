import styled from 'styled-components';
import Button from '../../commonComponents/button/Button';

interface IBannerWrapperProps {
    image: string;
    mobileImage: string;
}

export const BannerWrapper = styled.div<IBannerWrapperProps>`
    position: relative;
    height: 650px;
    background-image: url(${({ mobileImage }) => mobileImage});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 45% 70%;

    @media screen and (${({ theme }) => theme.device.xs}) {
        background-position: 50% 75%;
    }

    @media screen and (${({ theme }) => theme.device.sm}) {
        background-position: 50% 45%;
    }

    @media screen and (${({ theme }) => theme.device.md}) {
        background-position: 50% 72%;
        height: 780px;
    }

    @media screen and (${({ theme }) => theme.device.lg}) {
        background-image: url(${({ image }) => image});
        height: 500px;
    }

    @media screen and (${({ theme }) => theme.device.xl}) {
        background-position: 100% 30%;
        height: 530px;
    }
`;

export const BannerContentWrap = styled.div`
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    @media screen and (${({ theme }) => theme.device.xs}) {
        padding-top: 25px;
    }

    @media screen and (${({ theme }) => theme.device.lg}) {
        display: block;
        position: absolute;
        left: 5%;
    }

    @media screen and (${({ theme }) => theme.device.xl}) {
        display: block;
        position: absolute;
        left: 15%;
    }
`;

export const BannerContent = styled.div`
    position: relative;
`;

export const MainTitle = styled.h1`
    font-size: 3.4rem;
    font-family: ${({ theme }) => theme.fontFamily.notoSansKr};
    font-weight: ${({ theme }) => theme.fontWeight.notoSanKr.bold};

    @media screen and (${({ theme }) => theme.device.sm}) {
        font-size: ${({ theme }) => theme.fontSizes.xxl};
    }
`;

export const InnerContent = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 5px;
`;

interface IBannerContentSeparator {
    separator?: boolean;
}

export const BannerContentSeparator = styled.div<IBannerContentSeparator>`
    position: relative;
    width: 65px;

    &::after {
        position: absolute;
        content: '';
        display: ${({ separator }) => (separator ? 'block' : 'none')};
        width: 55%;
        height: 2px;
        right: 10px;
        background-color: ${({ theme }) => theme.colors.primary.main};
    }

    @media screen and (${({ theme }) => theme.device.sm}) {
        width: 90px;
    }

    @media screen and (${({ theme }) => theme.device.lg}) {
        width: 110px;

        &::after {
            height: 4px;
            width: 60%;
        }
    }
`;

export const BannerRightContent = styled.div`
    position: relative;
`;

export const InnerTitle = styled.h2`
    font-size: 1.4rem;
    color: ${({ theme }) => theme.colors.primary.main};
    font-weight: ${({ theme }) => theme.fontWeight.prompt.regular};
    text-transform: uppercase;

    @media screen and (${({ theme }) => theme.device.sm}) {
        font-size: ${({ theme }) => theme.fontSizes.lg};
    }
`;

export const BannerText = styled.p`
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.black.light};
    font-weight: ${({ theme }) => theme.fontWeight.prompt.regular};
    margin-bottom: 10px;

    span {
        color: ${({ theme }) => theme.colors.primary.main};
        font-size: 2.4rem;
        line-height: 0.8;
    }

    @media screen and (${({ theme }) => theme.device.sm}) {
        font-size: 1.5rem;
    }
`;

export const BannerBtnWrap = styled.div`
    margin-top: 50px;

    @media screen and (${({ theme }) => theme.device.md}) {
        margin-top: 40px;
    }

    @media screen and (${({ theme }) => theme.device.lg}) {
        margin-left: 110px;
    }
`;

export const BannerButton = styled(Button)`
    padding: 15px 40px;
    font-size: ${({ theme }) => theme.fontSizes.md};
    border-radius: 30px;

    @media screen and (${({ theme }) => theme.device.lg}) {
        padding: 10px;
        font-size: ${({ theme }) => theme.fontSizes.sm};
    }
`;

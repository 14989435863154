import styled, { css } from 'styled-components';
import { IBreakpointSizes } from '../theme/breakpoints';

export interface IStyledImageProps {
    initialWidth?: number;
    mediaWidth?: {
        [K in IBreakpointSizes]?: number;
    };
}

export const Image = styled.img<IStyledImageProps>`
    width: ${({ initialWidth }) => (initialWidth ? `${initialWidth}px` : 'initial')};

    ${({ mediaWidth, theme }) =>
        mediaWidth?.xs &&
        css`
            @media screen and (${theme.device.xs}) {
                width: ${mediaWidth.xs}px;
            }
        `}
    ${({ mediaWidth, theme }) =>
        mediaWidth?.sm &&
        css`
            @media screen and (${theme.device.sm}) {
                width: ${mediaWidth.sm}px;
            }
        `}

    ${({ mediaWidth, theme }) =>
        mediaWidth?.md &&
        css`
            @media screen and (${theme.device.md}) {
                width: ${mediaWidth.md}px;
            }
        `}
    ${({ mediaWidth, theme }) =>
        mediaWidth?.lg &&
        css`
            @media screen and (${theme.device.lg}) {
                width: ${mediaWidth.lg}px;
            }
        `}

    ${({ mediaWidth, theme }) =>
        mediaWidth?.xl &&
        css`
            @media screen and (${theme.device.xl}){
                width: ${mediaWidth.xl}px;
            }
        `}
    ${({ mediaWidth, theme }) =>
        mediaWidth?.xxl &&
        css`
            @media screen and (${theme.device.xxl}) {
                width: ${mediaWidth.xxl}px;
            }
        `}
`;

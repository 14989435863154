import React from 'react';
import { CSSProperties } from 'styled-components';
import { StyledCard } from './card.style';

export type TCardVariants = 'primary' | 'secondary';
export interface ICardProps {
    borderRadius?: number;
    backgroundColor?: string;
    variants?: TCardVariants;
    style?: CSSProperties;
}

export const Card: React.FC<ICardProps> = (props) => {
    const { children } = props;

    return <StyledCard {...props}>{children}</StyledCard>;
};

const instagram = {
    data: [
        {
            "caption": "• Review : จากลูกค้าที่ใช้ผลิตภัณฑ์จริง\n\n• Line : chuchoice (มี@)\nhttps://lin.ee/VoFTG1q\n\n#chuchoice #ชูชอยซ์ #츄초이스 #chuchuday #chuchoicegirls #weightloss #newbodynewlife #newbodynewopportunity #ร่างใหม่ชีวิตใหม่",
            "id": "17944409284697422",
            "media_type": "IMAGE",
            "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/271859500_338603838087418_6137723202308481573_n.jpg?_nc_cat=103&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=2pMJAN9j4hgAX900P_p&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT9h1-Kp-5F2CdGwce7MK1yXQ4yTE5mwPylJhTOWAwIJ2Q&oe=61E70033",
            "permalink": "https://www.instagram.com/p/CYsuiKzPaeC/",
            "timestamp": "2022-01-14T05:32:08+0000",
            "username": "chuchoice.official"
        },
        {
            "caption": "วิตามินควบคุมน้ำหนัก ที่สาวเกาหลีเลือกใช้ ! 🇰🇷\n\nChu Chu Day 츄 츄 데이 X4 ผลิตภัณฑ์แนวคิดใหม่ ช่วยเผาผลาญไขมันส่วนเกิน ยับยั้งคาร์โบไฮเดรตไม่ให้เปลี่ยนเป็นไขมัน และช่วยระบบขับถ่ายให้ดียิ่งขึ้นถึง 4 เท่า \n\n• Line : chuchoice (มี@)\nhttps://lin.ee/VoFTG1q\n\n#chuchoice #ชูชอยซ์ #츄초이스 #chuchuday #chuchoicegirls #weightloss #newbodynewlife #newbodynewopportunity #ร่างใหม่ชีวิตใหม่",
            "id": "17946292972666947",
            "media_type": "IMAGE",
            "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/271809964_447634570042938_6401350049187309175_n.jpg?_nc_cat=100&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=CotHOXltWvoAX-rpB4C&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT8iyNlQ7YMp460_0pxT4FLopKU9XXEWZfTIdDM8fR6pSg&oe=61E64251",
            "permalink": "https://www.instagram.com/p/CYqHTQ_v0gO/",
            "timestamp": "2022-01-13T05:10:50+0000",
            "username": "chuchoice.official"
        },
        {
            "caption": "• 𝐂𝐡𝐮 𝐂𝐡𝐨𝐢𝐜𝐞 ผลิตภัณฑ์คุณภาพจากผู้ผลิตอาหารเสริมอันดับ 1 ของประเทศเกาหลี การันตีความปลอดภัยด้วย อย.ประเทศไทย และเกาหลี เพราะ 𝐂𝐡𝐮 𝐂𝐡𝐨𝐢𝐜𝐞ให้ ความสำคัญกับผลลัพธ์ และความปลอดภัยของผู้บริโภคเป็นอันดับแรก\n\n• Line : chuchoice (มี@)\nhttps://lin.ee/VoFTG1q\n\n#chuchoice #ชูชอยซ์ #츄초이스 #chuchuday #chuchoicegirls #weightloss #newbodynewlife #newbodynewopportunity #ร่างใหม่ชีวิตใหม่",
            "id": "17985596848437093",
            "media_type": "IMAGE",
            "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/271470808_668056340869887_1626878164985991962_n.jpg?_nc_cat=101&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=5QpCKO6rDBcAX8zi9dH&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT_hRm8cpHj92ArqkKgqKhNtx2qMvr3ktfP3CmGe2NplTA&oe=61E5D921",
            "permalink": "https://www.instagram.com/p/CYigUabvURL/",
            "timestamp": "2022-01-10T06:15:31+0000",
            "username": "chuchoice.official"
        },
        {
            "caption": "ตัวช่วยควบคุมน้ำหนัก ด้วยประสิทธิภาพสูงสุดถึง 4 เท่า ! \n\nChu Choice 츄츄데이 X4\n• สูตรใหม่จากโรงงานอันดับ 1 ในเกาหลี...วิตามินช่วยควบคุมน้ำหนัก\n• ช่วยเร่งเผาผลาญไขมันส่วนเกิน\n• ยับยั้งการสังเคราะห์คาร์โบไฮเดรตให้เป็นไขมัน\n• มีแร่ธาตุเสริมช่วยเพิ่มพลังงาน\n• กระตุ้นการขับถ่ายให้ดียิ่งขึ้น\n• ประสิทธิภาพสูง 4 เท่า\n• ส่วนผสมและวิตามิน 14 ชนิด จาก 7 ประเทศ ใน 3 ทวีปและนอกจากนี้ยังมีส่วนผสมท่ีได้รับสิทธิบัตรจาก 7 ประเทศ\n• Made in Korea 100%\n• รับรองความปลอดภัยด้วย อย.\n\n• Line : chuchoice (มี@)\nhttps://lin.ee/VoFTG1q\n\n#chuchoice #ชูชอยซ์ #츄초이스 #chuchuday #chuchoicegirls #weightloss #newbodynewlife #newbodynewopportunity #ร่างใหม่ชีวิตใหม่",
            "id": "17924789464944111",
            "media_type": "IMAGE",
            "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/271301582_301269841948526_3044966660111926862_n.jpg?_nc_cat=104&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=-COSjKk6IQAAX_X8msM&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT-yMuOxvhOw1Yi3tvSfrOW2Q9uVM82NDpLPd7RLfZraHA&oe=61E55351",
            "permalink": "https://www.instagram.com/p/CYarzoXvTIy/",
            "timestamp": "2022-01-07T05:21:58+0000",
            "username": "chuchoice.official"
        },
        {
            "caption": "Diet Plan Chu Challenge\n\nตารางอาหารแคลอรี่ต่ำ สำหรับลดน้ำหนักแบบง่ายไปกับ ชู ชอยซ์\n\n• Line : chuchoice (มี@)\nhttps://lin.ee/VoFTG1q\n\n#chuchoice #ชูชอยซ์ #츄초이스 #chuchuday #chuchoicegirls #weightloss #newbodynewlife #newbodynewopportunity #ร่างใหม่ชีวิตใหม่",
            "id": "18204094261185429",
            "media_type": "IMAGE",
            "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/271303901_619564979251519_3371205439108082266_n.jpg?_nc_cat=106&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=6oK5spUueIcAX88rMlc&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT9_vMpNftuLnER_7LQmYLG5Q5qBXtY6r3l92LpanfBovg&oe=61E57CD8",
            "permalink": "https://www.instagram.com/p/CYYIh3WvZWi/",
            "timestamp": "2022-01-06T05:35:14+0000",
            "username": "chuchoice.official"
        },
        {
            "caption": "\" เพียงวันละ 1 ซอง ทานหล้งตื่นนอน ก่อนอาหารเช้า \" \n\nChu Choice 츄츄데이 X4 \nทางเลือกหุ่นเพรียวอย่าง ปลอดภัย ผลิตจากศาสตร์ชั้นสูงในเกาหลี เร่งการเผาผลาญ ลดไขมัน ช่วยเพิ่มพลังงาน กระตุ้นการขับถ่ายถึง 4 เท่า\n• Made in Korea 100%\n• รับรองความปลอดภัยด้วย อย.\n\n• โปรโมชั่นพิเศษ • \nซื้อ 1 กล่อง แถมฟรีอีก 1 กล่อง ราคาเพียง 1,290.- บาท \nจำกัดแค่ 500 สิทธิ์เท่านั้น\n\n• Line : chuchoice (มี@)\nhttps://lin.ee/VoFTG1q\n\n#chuchoice #ชูชอยซ์ #츄초이스 #chuchuday #chuchoicegirls #weightloss #newbodynewlife #newbodynewopportunity #ร่างใหม่ชีวิตใหม่",
            "id": "18148599550229156",
            "media_type": "IMAGE",
            "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/271275234_1330952777351427_662190468734449455_n.jpg?_nc_cat=111&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=ALm04xmqXG4AX86tD-b&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT9QL9wB9k4K7Ufvvc52nsl9fJGBA7jmu9jui_JtvLQ6TQ&oe=61E5F71E",
            "permalink": "https://www.instagram.com/p/CYVhRp8PbiW/",
            "timestamp": "2022-01-05T05:13:45+0000",
            "username": "chuchoice.official"
        },
        {
            "caption": "HAPPY NEW YEAR 2022 🎆\n\nAll about Health, Life, You. It’s Chu Choice\n\n#chuchoice #ชูชอยซ์ #츄초이스 #chuchuday #chuchoicegirls #weightloss #newbodynewlife #newbodynewopportunity #ร่างใหม่ชีวิตใหม่",
            "id": "17931959218822964",
            "media_type": "IMAGE",
            "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/270059793_452847809628384_2530330401713529866_n.jpg?_nc_cat=109&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=DQ3iKB3fK7QAX9qnh6r&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT_gHVJ4rzDFAgcc7hnP4Btfc0s8-3-HZzHG8JgUcZM9rg&oe=61E65839",
            "permalink": "https://www.instagram.com/p/CYJ62-dvrA_/",
            "timestamp": "2021-12-31T17:06:25+0000",
            "username": "chuchoice.official"
        },
        {
            "caption": "\" 1 กล่องทานได้ 14 วัน \"\n\nChu Choice 츄츄데이 X4 \nทางเลือกหุ่นเพรียวอย่าง ปลอดภัย ผลิตจากศาสตร์ชั้นสูงในเกาหลี เร่งการเผาผลาญ ลดไขมัน ช่วยเพิ่มพลังงาน กระตุ้นการขับถ่ายถึง 4 เท่า\n• Made in Korea 100%\n• รับรองความปลอดภัยด้วย อย.\n\n• จำกัดแค่ 500 สิทธิ์เท่านั้น • \nซื้อ 1 กล่อง แถมฟรีอีก 1 กล่อง ราคาเพียง 1,290.- บาท \n\n• Line : chuchoice (มี@)\nhttps://lin.ee/VoFTG1q\n\n#chuchoice #ชูชอยซ์ #츄초이스 #chuchuday #chuchoicegirls #weightloss #newbodynewlife #newbodynewopportunity #ร่างใหม่ชีวิตใหม่",
            "id": "17895787781392175",
            "media_type": "IMAGE",
            "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/270575384_139566078467742_8223076209792499859_n.jpg?_nc_cat=100&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=4XdUSW8QU_kAX8r485W&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT-agwEbgR_LZD5U0hkGuTp8SSgD60Dvvd5M4tjKjlyocA&oe=61E72405",
            "permalink": "https://www.instagram.com/p/CYIv7K-tg1r/",
            "timestamp": "2021-12-31T06:11:37+0000",
            "username": "chuchoice.official"
        },
        {
            "caption": "วิตามินควบคุมน้ำหนัก ที่สาวเกาหลีเลือกใช้ ! \nChu Chu Day 츄 츄 데이 X4 ผลิตภัณฑ์แนวคิดใหม่ ช่วยเผาผลาญไขมันส่วนเกิน ยับยั้งคาร์โบไฮเดรตไม่ให้เปลี่ยนเป็นไขมัน และช่วยระบบขับถ่ายให้ดียิ่งขึ้นถึง 4 เท่า \n\n• จำกัดแค่ 500 สิทธิ์เท่านั้น • \nซื้อ 1 กล่อง แถมฟรีอีก 1 กล่อง ราคาเพียง 1,290.- บาท \n\n• Line : chuchoice (มี@)\nhttps://lin.ee/VoFTG1q\n\n#chuchoice #ชูชอยซ์ #츄초이스 #chuchuday #chuchoicegirls #weightloss #newbodynewlife #newbodynewopportunity #ร่างใหม่ชีวิตใหม่",
            "id": "17930082604949725",
            "media_type": "CAROUSEL_ALBUM",
            "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/270337248_1121225268616041_7791033490011903093_n.jpg?_nc_cat=104&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=ge42WdUS-GEAX88BHzT&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT_QF61PmHsLw6thJruUvifpBuKZWKclLmNeKosNFdCgIw&oe=61E5C4D7",
            "permalink": "https://www.instagram.com/p/CYGMWKuPuOX/",
            "timestamp": "2021-12-30T06:22:15+0000",
            "username": "chuchoice.official"
        },
        {
            "caption": "Chu Choice ไม่ใช่ยาลดความอ้วน แต่เป็น วิตามิน B Complex \n• สูตรใหม่จากโรงงานอันดับ 1 ในเกาหลี...วิตามินช่วยควบคุมน้ำหนัก\n• ช่วยเร่งเผาผลาญไขมันส่วนเกิน\n• ยับยั้งการสังเคราะห์คาร์โบไฮเดรตให้เป็นไขมัน\n• มีแร่ธาตุเสริมช่วยเพิ่มพลังงาน\n• กระตุ้นการขับถ่ายให้ดียิ่งขึ้น\n• ประสิทธิภาพสูง 4 เท่า\n• ส่วนผสมและวิตามิน 14 ชนิด จาก 7 ประเทศ ใน 3 ทวีปและนอกจากนี้ยังมีส่วนผสมท่ีได้รับสิทธิบัตรจาก 7 ประเทศ\n• Made in Korea 100%\n• รับรองความปลอดภัยด้วย อย.\n\n• จำกัดแค่ 500 สิทธิ์เท่านั้น • \nซื้อ 1 กล่อง แถมฟรีอีก 1 กล่อง ราคาเพียง 1,290.- บาท \n\n• Line : chuchoice (มี@)\nhttps://lin.ee/VoFTG1q\n\n#chuchoice #ชูชอยซ์ #츄초이스 #chuchuday #chuchoicegirls #weightloss #newbodynewlife #newbodynewopportunity #ร่างใหม่ชีวิตใหม่",
            "id": "17853879239682282",
            "media_type": "CAROUSEL_ALBUM",
            "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/270085286_137821008668917_7814969788709753028_n.jpg?_nc_cat=101&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=ZkzL6bFUkhIAX_ieCYu&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT9AVEgoZV0b614qXHF59rmPCvu6vQ9Blr61OnwiHvabSw&oe=61E5D5DD",
            "permalink": "https://www.instagram.com/p/CYA7kPevEMt/",
            "timestamp": "2021-12-28T05:19:26+0000",
            "username": "chuchoice.official"
        },
        {
            "caption": "วิตามินควบคุมน้ำหนัก ที่สาวเกาหลีเลือกใช้ ! \nChu Chu Day 츄 츄 데이 X4 ผลิตภัณฑ์แนวคิดใหม่ ช่วยเผาผลาญไขมันส่วนเกิน ยับยั้งคาร์โบไฮเดรตไม่ให้เปลี่ยนเป็นไขมัน และช่วยระบบขับถ่ายให้ดียิ่งขึ้นถึง 4 เท่า \n\n• จำกัดแค่ 500 สิทธิ์เท่านั้น • \nซื้อ 1 กล่อง แถมฟรีอีก 1 กล่อง ราคาเพียง 1,290.- บาท \n\n• Line : chuchoice (มี@)\nhttps://lin.ee/VoFTG1q\n\n#chuchoice #ชูชอยซ์ #츄초이스 #chuchuday #chuchoicegirls #weightloss #newbodynewlife #newbodynewopportunity #ร่างใหม่ชีวิตใหม่",
            "id": "17981937778445311",
            "media_type": "CAROUSEL_ALBUM",
            "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/270193245_308086541231208_3982990516951531989_n.jpg?_nc_cat=105&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=MqjDl6fsx7YAX-vVFnw&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT8-S0P5c6MEPRl7NoF7w-x_zOGwXNnU1it30D7yYjlt8Q&oe=61E6FFE0",
            "permalink": "https://www.instagram.com/p/CX_AUMJvQ-l/",
            "timestamp": "2021-12-27T11:22:27+0000",
            "username": "chuchoice.official"
        },
        {
            "caption": "Chu Choice 츄츄데이 X4 \nทางเลือกหุ่นเพรียวอย่าง ปลอดภัย ผลิตจากศาสตร์ชั้นสูงในเกาหลี เร่งการเผาผลาญ ลดไขมัน ช่วยเพิ่มพลังงาน กระตุ้นการขับถ่ายถึง 4 เท่า\n• Made in Korea 100%\n• รับรองความปลอดภัยด้วย อย.\n\n• โปรโมชั่นพิเศษ • \nซื้อ 1 กล่อง แถมฟรีอีก 1 กล่อง ราคาเพียง 1,290.- บาท \nจำกัดแค่ 500 สิทธิ์เท่านั้น\n\n• Line : chuchoice (มี@)\nhttps://lin.ee/VoFTG1q\n\n#chuchoice #ชูชอยซ์ #츄초이스 #chuchuday #chuchoicegirls #weightloss #newbodynewlife #newbodynewopportunity #ร่างใหม่ชีวิตใหม่",
            "id": "17912216231123973",
            "media_type": "CAROUSEL_ALBUM",
            "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/270068437_1324514204661397_4012304930251305628_n.jpg?_nc_cat=104&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=JjgX_V1kh5UAX8PzUM1&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT8njeaN8jlGm5oEA2J9FFmAnuSxRp6V8NtbGyP4Yh9nOw&oe=61E62767",
            "permalink": "https://www.instagram.com/p/CX5SkyDPCNh/",
            "timestamp": "2021-12-25T06:06:33+0000",
            "username": "chuchoice.official"
        },
        {
            "caption": "Chu Choice 츄츄데이 X4 \nทางเลือกหุ่นเพรียวอย่าง ปลอดภัย ผลิตจากศาสตร์ชั้นสูงในเกาหลี เร่งการเผาผลาญ ลดไขมัน ช่วยเพิ่มพลังงาน กระตุ้นการขับถ่ายถึง 4 เท่า\n• Made in Korea 100%\n• รับรองความปลอดภัยด้วย อย.\n\n• โปรโมชั่นพิเศษ • \nซื้อ 1 กล่อง แถมฟรีอีก 1 กล่อง ราคาเพียง 1,290.- บาท \nจำกัดแค่ 500 สิทธิ์เท่านั้น\n\n• Line : chuchoice (มี@)\nhttps://lin.ee/VoFTG1q\n\n#chuchoice #ชูชอยซ์ #츄초이스 #chuchuday #chuchoicegirls #weightloss #newbodynewlife #newbodynewopportunity #ร่างใหม่ชีวิตใหม่",
            "id": "17988111085423571",
            "media_type": "CAROUSEL_ALBUM",
            "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/269966901_965446197723175_4215163949227685224_n.jpg?_nc_cat=104&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=7rR3iRGK7noAX9en4Bh&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT9IzsvAa4QjCzkTsosdgylzlb9SmuuzgCP5bSIFXXV2kA&oe=61E5B099",
            "permalink": "https://www.instagram.com/p/CX2rix1vDSR/",
            "timestamp": "2021-12-24T05:47:01+0000",
            "username": "chuchoice.official"
        },
        {
            "caption": "Chu Choice 츄츄데이 X4 \nทางเลือกหุ่นเพรียวอย่าง ปลอดภัย ผลิตจากศาสตร์ชั้นสูงในเกาหลี เร่งการเผาผลาญ ลดไขมัน ช่วยเพิ่มพลังงาน กระตุ้นการขับถ่ายถึง 4 เท่า\n• Made in Korea 100%\n• รับรองความปลอดภัยด้วย อย.\n\n• โปรโมชั่นพิเศษ • \nซื้อ 1 กล่อง แถมฟรีอีก 1 กล่อง ราคาเพียง 1,290.- บาท \nจำกัดแค่ 500 สิทธิ์เท่านั้น\n\n• Line : chuchoice (มี@)\nhttps://lin.ee/VoFTG1q\n\n#chuchoice #ชูชอยซ์ #츄초이스 #chuchuday #chuchoicegirls #weightloss #newbodynewlife #newbodynewopportunity #ร่างใหม่ชีวิตใหม่",
            "id": "17958120700557035",
            "media_type": "CAROUSEL_ALBUM",
            "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/269780475_741862453872106_9219177178537311502_n.jpg?_nc_cat=101&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=ncpYQrRKfKMAX-HPOBA&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT8eoi9Fw_Tw-mIhpOnc2Vkrh7KJTBFw7RhZ3ywmyEwBsg&oe=61E56A54",
            "permalink": "https://www.instagram.com/p/CX0ImyEPsdl/",
            "timestamp": "2021-12-23T06:03:15+0000",
            "username": "chuchoice.official"
        },
        {
            "caption": "• 𝐂𝐡𝐮 𝐂𝐡𝐨𝐢𝐜𝐞 ผลิตภัณฑ์คุณภาพจากผู้ผลิตอาหารเสริมอันดับ 1 ของประเทศเกาหลี การันตีความปลอดภัยด้วย อย.ประเทศไทย และเกาหลี เพราะ 𝐂𝐡𝐮 𝐂𝐡𝐨𝐢𝐜𝐞ให้ ความสำคัญกับผลลัพธ์ และความปลอดภัยของผู้บริโภคเป็นอันดับแรก\n\n• จำกัดแค่ 500 สิทธิ์เท่านั้น • \nซื้อ 1 กล่อง แถมฟรีอีก 1 กล่อง ราคาเพียง 1,290.- บาท \n\n• Line : chuchoice (มี@)\nhttps://lin.ee/VoFTG1q\n\n#chuchoice #ชูชอยซ์ #츄초이스 #chuchuday #chuchoicegirls #weightloss #newbodynewlife #newbodynewopportunity #ร่างใหม่ชีวิตใหม่",
            "id": "17986572934426450",
            "media_type": "CAROUSEL_ALBUM",
            "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/269719989_1973931456109308_1936042866637121808_n.jpg?_nc_cat=100&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=DyDXC9gqS30AX8m4Wtu&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT8IljNYoyPqH1n_BY4eu3u8bFESx-pRWnrv8-GgTqlhEQ&oe=61E64B15",
            "permalink": "https://www.instagram.com/p/CXxgnsnv0YM/",
            "timestamp": "2021-12-22T05:35:22+0000",
            "username": "chuchoice.official"
        },
        {
            "caption": "Chu Choice ไม่ใช่ยาลดความอ้วน แต่เป็น วิตามิน B Complex \n• สูตรใหม่จากโรงงานอันดับ 1 ในเกาหลี...วิตามินช่วยควบคุมน้ำหนัก\n• ช่วยเร่งเผาผลาญไขมันส่วนเกิน\n• ยับยั้งการสังเคราะห์คาร์โบไฮเดรตให้เป็นไขมัน\n• มีแร่ธาตุเสริมช่วยเพิ่มพลังงาน\n• กระตุ้นการขับถ่ายให้ดียิ่งขึ้น\n• ประสิทธิภาพสูง 4 เท่า\n• ส่วนผสมและวิตามิน 14 ชนิด จาก 7 ประเทศ ใน 3 ทวีปและนอกจากนี้ยังมีส่วนผสมท่ีได้รับสิทธิบัตรจาก 7 ประเทศ\n• Made in Korea 100%\n• รับรองความปลอดภัยด้วย อย.\n\n• จำกัดแค่ 500 สิทธิ์เท่านั้น • \nซื้อ 1 กล่อง แถมฟรีอีก 1 กล่อง ราคาเพียง 1,290.- บาท \n\n• Line : chuchoice (มี@)\nhttps://lin.ee/VoFTG1q\n\n#chuchoice #ชูชอยซ์ #츄초이스 #chuchuday #chuchoicegirls #weightloss #newbodynewlife #newbodynewopportunity #ร่างใหม่ชีวิตใหม่",
            "id": "17923566560044247",
            "media_type": "CAROUSEL_ALBUM",
            "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/269763476_450335523156900_5709717471053990193_n.jpg?_nc_cat=100&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=VVzh39qXq3gAX-Qc7hE&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT_U2C3snR25eXumiX0kIhNynIh_p4O4p9y0gWsB_gKdPQ&oe=61E71492",
            "permalink": "https://www.instagram.com/p/CXvBXrIPHAl/",
            "timestamp": "2021-12-21T06:23:49+0000",
            "username": "chuchoice.official"
        },
        {
            "caption": "• Review : จากผู้ใช้จริง\n\n• โปรโมชั่นพิเศษ • \nซื้อ 1 กล่อง แถมฟรีอีก 1 กล่อง ราคาเพียง 1,290.- บาท \nจำกัดแค่ 500 สิทธิ์เท่านั้น\n\n• Line : chuchoice (มี@)\nhttps://lin.ee/VoFTG1q\n\n#chuchoice #ชูชอยซ์ #츄초이스 #chuchuday #chuchoicegirls #weightloss #newbodynewlife #newbodynewopportunity #ร่างใหม่ชีวิตใหม่",
            "id": "17943643318674618",
            "media_type": "CAROUSEL_ALBUM",
            "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/269477244_871582180201757_133051261049666200_n.jpg?_nc_cat=102&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=XI7KeUaUFlsAX8CAuAG&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT_i4sIbFgAdAQssu3RDxEFB7B8sxa5t1RTQy2NIkEHuXA&oe=61E54BBE",
            "permalink": "https://www.instagram.com/p/CXs8IQ5vNFH/",
            "timestamp": "2021-12-20T10:59:32+0000",
            "username": "chuchoice.official"
        },
        {
            "caption": "• เริ่มต้นวันใหม่ไปกับ Chu Choice ทางเลือกหุ่นเพรียวอย่าง ปลอดภัย ผลิตจากศาสตร์ชั้นสูงในเกาหลี เร่งการเผาผลาญ ลดไขมัน ช่วยเพิ่มพลังงาน กระตุ้นการขับถ่ายถึง 4 เท่า\n➡️ Made in Korea 100%\n➡️ รับรองความปลอดภัยด้วย อย.\n\n• โปรโมชั่นพิเศษ • \nซื้อ 1 กล่อง แถมฟรีอีก 1 กล่อง ราคาเพียง 1,290.- บาท \nจำกัดแค่ 500 สิทธิ์เท่านั้น\n\n• Line : chuchoice (มี@)\nhttps://lin.ee/VoFTG1q\n\n#chuchoice #ชูชอยซ์ #츄초이스 #chuchuday #chuchoicegirls #weightloss #newbodynewlife #newbodynewopportunity #ร่างใหม่ชีวิตใหม่",
            "id": "17964482293502259",
            "media_type": "CAROUSEL_ALBUM",
            "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/267844674_1773596759498087_8633533142403869193_n.jpg?_nc_cat=101&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=OPKsxrYbvywAX_ZlWv8&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT82npqWsKFFtFyWIanr-SAX87e1xZwK9RE2eKMeenY5ZQ&oe=61E6014C",
            "permalink": "https://www.instagram.com/p/CXksxpRPdXn/",
            "timestamp": "2021-12-17T06:11:27+0000",
            "username": "chuchoice.official"
        },
        {
            "caption": "𝐂𝐡𝐮 𝐂𝐡𝐨𝐢𝐜𝐞 ผลิตภัณฑ์คุณภาพจากผู้ผลิตอาหารเสริมอันดับ 1 ของประเทศเกาหลี การันตีความปลอดภัยด้วย อย.ประเทศไทย และเกาหลี เพราะ 𝐂𝐡𝐮 𝐂𝐡𝐨𝐢𝐜𝐞 ให้ความสำคัญกับผลลัพธ์ และความปลอดภัยของผู้บริโภคเป็นอันดับแรก\n\n• โปรโมชั่นพิเศษ • \nซื้อ 1 กล่อง แถมฟรีอีก 1 กล่อง ราคาเพียง 1,290.- บาท \nจำกัดแค่ 500 สิทธิ์เท่านั้น\n\n• Line : chuchoice (มี@)\nhttps://lin.ee/VoFTG1q\n\n#chuchoice #ชูชอยซ์ #츄초이스 #chuchuday #chuchoicegirls #weightloss #newbodynewlife #newbodynewopportunity #ร่างใหม่ชีวิตใหม่",
            "id": "17885503274503000",
            "media_type": "CAROUSEL_ALBUM",
            "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/267701465_1075079216613987_8038990513573748023_n.jpg?_nc_cat=110&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=mcDRy52VB0YAX96i4Fo&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT87ExWc7NYbbn2XOw4kY3naxGSPpHK8EsBIKDYDOG0snQ&oe=61E5C578",
            "permalink": "https://www.instagram.com/p/CXfj4O2vcYO/",
            "timestamp": "2021-12-15T06:17:30+0000",
            "username": "chuchoice.official"
        },
        {
            "caption": "วิตามินควบคุมน้ำหนัก Chu Chu Day 츄 츄 데이 X4 ผลิตภัณฑ์แนวคิดใหม่ ช่วยเผาผลาญไขมันส่วนเกิน ยังยั้งคาร์โบไฮเดรตไม่ให้เปลี่ยนเป็นไขมัน และช่วยระบบขับถ่ายให้ดียิ่งขึ้นถึง 4 เท่า \n\n• จำกัดแค่ 500 สิทธิ์เท่านั้น • \nซื้อ 1 กล่อง แถมฟรีอีก 1 กล่อง ราคาเพียง 1,290.- บาท \n\n• Line : chuchoice (มี@)\nhttps://lin.ee/VoFTG1q\n\n#chuchoice #ชูชอยซ์ #츄초이스 #chuchuday #chuchoicegirls #weightloss #newbodynewlife #newbodynewopportunity #ร่างใหม่ชีวิตใหม่",
            "id": "17953511455586497",
            "media_type": "IMAGE",
            "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/266616036_615234816596563_6191082184298455836_n.jpg?_nc_cat=105&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=4TgrtbZYd28AX8Y3zXr&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT8_d79-YNzfDO4PBvbQFh8659mCEbUBDd17gDRSzMHHaw&oe=61E62C88",
            "permalink": "https://www.instagram.com/p/CXdlQ5jPuP0/",
            "timestamp": "2021-12-14T11:51:08+0000",
            "username": "chuchoice.official"
        },
        {
            "caption": "ชู ชอยซ์ ตัวช่วยเพิ่มประสิทธิภาพในการออกกำลังกาย ช่วยเร่งเผาผลาญไขมันส่วนเกิน ประสิทธิภาพสูง 4 เท่า\n\n• จำกัดแค่ 500 สิทธิ์เท่านั้น • \nซื้อ 1 กล่อง แถมฟรีอีก 1 กล่อง ราคาเพียง 1,290.- บาท \n\n• Line : chuchoice (มี@)\nhttps://lin.ee/VoFTG1q\n\n#chuchoice #ชูชอยซ์ #츄초이스 #chuchuday #chuchoicegirls #weightloss #newbodynewlife #newbodynewopportunity #ร่างใหม่ชีวิตใหม่",
            "id": "17937646159750297",
            "media_type": "IMAGE",
            "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/267294620_3002218530032499_8903879852413083074_n.jpg?_nc_cat=100&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=51yEUsI8xoYAX_g4ncZ&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT-Y3CwkDViJKlOdBTicbtrR1pj4HSCXTM1FslZnHMlNpg&oe=61E5F84D",
            "permalink": "https://www.instagram.com/p/CXbA3C-PFsc/",
            "timestamp": "2021-12-13T11:54:33+0000",
            "username": "chuchoice.official"
        },
        {
            "caption": "ลดแรงส่งท้ายปี ! Chu Choice หุ่นสวยสุขภาพดีในราคาสุดพิเศษ ซื้อ 1 กล่อง แถมฟรีอีก 1 กล่อง ราคาเพียง 1,290.- บาท จำกัดแค่ 500 สิทธิ์เท่านั้น รีบเลย !\n\n🔺ช้อปสะดวกง่ายๆได้ที่🔺\nwww.chuchoice.com\nIG : chuchoice.official\nFB : Chu Choice\n+66 (0) 94 503 3864\nLine : chuchoice (มี@)\nhttps://lin.ee/VoFTG1q\n\n#chuchoice #ชูชอยซ์ #츄초이스 #chuchuday #chuchoicegirls #weightloss #newbodynewlife #newbodynewopportunity #ร่างใหม่ชีวิตใหม่",
            "id": "18196658125126340",
            "media_type": "IMAGE",
            "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/265079652_585946235824244_907221881814119325_n.jpg?_nc_cat=102&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=qNNEmQeuF5cAX9NkFMM&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT-wJR5oKq-JivxuNpWld2hmSTVzxZ7MX07bJrwYijE1wg&oe=61E5B4B4",
            "permalink": "https://www.instagram.com/p/CXTM-LBvVCZ/",
            "timestamp": "2021-12-10T11:06:27+0000",
            "username": "chuchoice.official"
        },
        {
            "caption": "• Review : จากผู้ใช้จริง\n\n• โปรโมชั่นสุดคุ้ม • \n2 กล่องเพียง 1,490.- แถมฟรี Mini Pack 2 ซอง❗️\n\n• Line : chuchoice (มี@)\nhttps://lin.ee/VoFTG1q\n\n#chuchoice #ชูชอยซ์ #츄초이스 #chuchuday #chuchoicegirls #weightloss #newbodynewlife #newbodynewopportunity #ร่างใหม่ชีวิตใหม่",
            "id": "18187739071148688",
            "media_type": "CAROUSEL_ALBUM",
            "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/265934585_428718822056707_8129802249925999303_n.jpg?_nc_cat=103&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=8_jRZOVCDkoAX8KOH_Z&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT-S25mLkHEEQGlShP-OZbsF3U89JwxrzjaGJ8oJ2AEIdg&oe=61E60363",
            "permalink": "https://www.instagram.com/p/CXQF6MvPMUe/",
            "timestamp": "2021-12-09T06:07:01+0000",
            "username": "chuchoice.official"
        },
        {
            "caption": "• Review : จากผู้ใช้จริง\n\n• โปรโมชั่นสุดคุ้ม • \n2 กล่องเพียง 1,490.- แถมฟรี Mini Pack 2 ซอง❗️\n\n• Line : chuchoice (มี@)\nhttps://lin.ee/VoFTG1q\n\n#chuchoice #ชูชอยซ์ #츄초이스 #chuchuday #chuchoicegirls #weightloss #newbodynewlife #newbodynewopportunity #ร่างใหม่ชีวิต",
            "id": "17917944776113310",
            "media_type": "CAROUSEL_ALBUM",
            "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/264598669_502805340896821_5415380307680684571_n.jpg?_nc_cat=108&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=XgpQFL8j-KkAX-NxXB_&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT9C04lrpe8hs3ncn6OluEpsTaP1yjLeaJ4_vjdsLdMJCA&oe=61E6E71A",
            "permalink": "https://www.instagram.com/p/CXNg3jUvPxh/",
            "timestamp": "2021-12-08T06:04:52+0000",
            "username": "chuchoice.official"
        }
    ],
    paging: {
        cursors: {
            before:
                'QVFIUndNVGljd0c2SXJDaWpERVFGcjhXejNWUDB0WTNNQ1lTbzd0Ty1JcHBMX0VVYVFSU0dyd0QyUFZABRWVoeU0weHk5WUdiS09Obkh6am9LV0hUU0czUDFB',
            after:
                'QVFIUlZAxR1dGRk9fXzBsbTEtVXdFTUxKTmxScnVneUVfWVQ1S1ViVkVyWWlhRFg1Qy1oVThnTkF6elVjeHk5eFhSS1kxVVpaUHVyU2REODdkRHF3YWV4WlBB',
        },
        next:
            'https://graph.instagram.com/v11.0/17841445511620828/media?access_token=IGQVJXb1lnUkhCRzJKN1UzYUpkaUE0UkM0LUxPZAU5naGVucWMyeVltLURraUtKUFRFU3VBYjNCWGZAnVnBqZA1hzVjdYNU9sWWx3d3RaQjRadHcyZA3NJLVhKengwTV81dllFV3JnVzZAR&fields=caption%2Cid%2Cmedia_type%2Cmedia_url%2Cpermalink%2Cthumbnail_url%2Ctimestamp%2Cusername&limit=25&after=QVFIUlZAxR1dGRk9fXzBsbTEtVXdFTUxKTmxScnVneUVfWVQ1S1ViVkVyWWlhRFg1Qy1oVThnTkF6elVjeHk5eFhSS1kxVVpaUHVyU2REODdkRHF3YWV4WlBB',
    },
};

export { instagram };

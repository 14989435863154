import styled from 'styled-components';
import Button from '../commonComponents/button/Button';
import { Paragraph } from '../commonComponents/typography/paragraph';
import { Title } from '../commonComponents/typography/title';
import { Card } from '../commonComponents/card/Card';

export const ProductSection = styled.div`
    padding: 24px 0;
    background-color: #f7f8fa;

    @media screen and (${({ theme }) => theme.device.lg}) {
        padding: 42px 0;
    }
`;

export const ProductCardRow = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 13px;

    @media screen and (${({ theme }) => theme.device.md}) {
        flex-direction: row;
        gap: 0;
    }
`;

export const ProductCardCol = styled.div`
    width: 100%;

    @media screen and (${({ theme }) => theme.device.sm}) {
        flex: 0.9;
        padding-left: 12px;
        padding-right: 12px;
    }
`;

export const WeightManagementImage = styled.img`
    width: 100%;
`;

export const WeightMgmtTitle = styled.h1`
    position: absolute;
    top: 12px;
    left: 0;
    width: 100%;
    color: ${({ theme }) => theme.colors.white.main};
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.xl};
    font-family: ${({ theme }) => theme.fontFamily.notoSansKr};
    font-weight: ${({ theme }) => theme.fontWeight.notoSanKr.bold};
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: ${({ theme }) => theme.colors.black.darK};
    text-shadow: 1px 0 0 #000, 1px 1px 0 #000, 2px 0 0 #000, 2px 2px 0 #000;

    @media screen and (${({ theme }) => theme.device.md}) {
        font-size: ${({ theme }) => theme.fontSizes.xl};
    }

    @media screen and (${({ theme }) => theme.device.lg}) {
        font-size: ${({ theme }) => theme.fontSizes.xxl};
    }
`;

export const WeightMgmtTitleEn = styled.h2`
    position: absolute;
    bottom: 45px;
    left: 0;
    width: 100%;
    color: ${({ theme }) => theme.colors.black.lighter};
    text-align: center;
    font-size: 0.85rem;
    font-weight: ${({ theme }) => theme.fontWeight.prompt.bold};
    text-transform: uppercase;

    @media screen and (${({ theme }) => theme.device.xs}) {
        font-size: 1.05rem;
        bottom: 58px;
    }

    @media screen and (${({ theme }) => theme.device.md}) {
        font-size: ${({ theme }) => theme.fontSizes.xs};
        bottom: 52px;
    }

    @media screen and (${({ theme }) => theme.device.lg}) {
        font-size: 1.05rem;
        bottom: 58px;
    }

    @media screen and (${({ theme }) => theme.device.xl}) {
        font-size: 1.3rem;
        bottom: 72px;
    }
`;

export const WeightMgmtMadeIn = styled.div`
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (${({ theme }) => theme.device.md}) {
        font-size: 25px;
    }
`;

export const WeightMgmtMadeInText = styled.span`
    font-size: 0.65rem;
    font-weight: ${({ theme }) => theme.fontWeight.prompt.light};
    color: ${({ theme }) => theme.colors.black.light};
    margin-right: 10px;
    letter-spacing: 0.36px;

    @media screen and (${({ theme }) => theme.device.md}) {
        font-size: ${({ theme }) => theme.fontSizes.xs};
    }

    @media screen and (${({ theme }) => theme.device.lg}) {
        font-size: 1.05rem;
    }

    @media screen and (${({ theme }) => theme.device.lg}) {
        font-size: 1.2rem;
    }
`;

export const HomeSupplementCard = styled(Card)`
    padding: 25px 10px;
    height: 100%;

    @media screen and (${({ theme }) => theme.device.xs}) {
        padding: 25px 20px;
    }

    @media screen and (${({ theme }) => theme.device.lg}) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 38px 30px;
    }
`;

export const WeightMgmtUpperRightTitle = styled.h1`
    font-weight: ${({ theme }) => theme.fontWeight.prompt.medium};
    color: ${({ theme }) => theme.colors.primary.main};
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1.04px;
    font-size: 1.625rem;

    @media screen and (${({ theme }) => theme.device.lg}) {
        letter-spacing: 1.36px;
        font-size: 2.125rem;
    }

    @media screen and (${({ theme }) => theme.device.xl}) {
        font-size: ${({ theme }) => theme.fontSizes.lg};
    }
`;

export const WeightMgmtLowerRightTitle = styled.h1`
    font-weight: ${({ theme }) => theme.fontWeight.prompt.medium};
    color: ${({ theme }) => theme.colors.primary.main};
    font-size: 0.8rem;
    text-transform: uppercase;
    text-align: center;

    @media screen and (min-width: 375px) {
        font-size: 1.063rem;
    }

    @media screen and (${({ theme }) => theme.device.lg}) {
        font-size: 1.25rem;
    }

    @media screen and (${({ theme }) => theme.device.xl}) {
        font-size: 1.6rem;
    }
`;

export const WeightMgmtBenefitWrap = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 35px;
    margin-bottom: 46px;

    @media screen and (min-width: 375px) {
        gap: 18px;
    }

    @media screen and (${({ theme }) => theme.device.md}) {
        margin-bottom: 20px;
        margin-top: 30px;
    }

    @media screen and (${({ theme }) => theme.device.lg}) {
        gap: 25px;
    }

    @media screen and (${({ theme }) => theme.device.xl}) {
        margin: 0;
    }
`;

export const WeightMgmtBenefitIconWrap = styled.div`
    width: 78px;
    height: 78px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #fff;
    margin: 0 auto 8px auto;

    @media screen and (${({ theme }) => theme.device.lg}) {
        width: 103px;
        height: 103px;
        margin-bottom: 12px;
    }

    @media screen and (${({ theme }) => theme.device.xl}) {
        width: 124px;
        height: 124px;
    }
`;

export const WeightMgmtBenefitContent = styled.div`
    position: relative;
`;

export const WeightMgmtBenefitText = styled.p`
    color: ${({ theme }) => theme.colors.black.main};
    text-align: center;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: ${({ theme }) => theme.fontWeight.prompt.regular};

    @media screen and (${({ theme }) => theme.device.lg}) {
        font-size: 0.938rem;
    }

    @media screen and (${({ theme }) => theme.device.xl}) {
        font-size: 1.2rem;
    }
`;

export const WeightMgmtButtonWrap = styled.div`
    text-align: center;
`;

export const WeightMgmtProductLink = styled(Button)`
    letter-spacing: 0.75px;
    font-size: 0.938rem;
    padding: 10px 13px;
    color: ${({ theme }) => theme.colors.primary.dark};

    @media screen and (${({ theme }) => theme.device.lg}) {
        font-size: 1.5rem;
        padding: 10px 15px;
        border-radius: 30px;
    }
`;

// Hot Deal
export const HotDealSection = styled.section`
    padding: 21px 0;
`;

export const HotDealRow = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;

    @media screen and (${({ theme }) => theme.device.lg}) {
        flex-direction: row;
    }
`;

export const HotDealCol = styled.div`
    flex: 1;
    position: relative;

    @media screen and (${({ theme }) => theme.device.lg}) {
        padding-left: 12px;
        padding-right: 12px;
    }
`;

export const HotDealLeft = styled.div`
    text-align: center;
    position: relative;
    padding-bottom: 30px;
    height: 100%;

    @media screen and (${({ theme }) => theme.device.xs}) {
        padding-bottom: 45px;
    }

    @media screen and (${({ theme }) => theme.device.sm}) {
        padding-bottom: 60px;
    }

    @media screen and (${({ theme }) => theme.device.lg}) {
        padding-bottom: 0;
        padding-left: 65px;
        padding-top: 15px;
    }

    @media screen and (${({ theme }) => theme.device.xl}) {
        padding-top: 35px;
    }
`;

export const HotDealButton = styled(Button)`
    font-size: 0.813rem;
    padding: 10px 15px;

    @media screen and (min-width: 375px) {
        padding: 12px 17px;
    }

    @media screen and (${({ theme }) => theme.device.xs}) {
        padding: 10px 20px;
        font-size: ${({ theme }) => theme.fontSizes.sm};
    }

    @media screen and (${({ theme }) => theme.device.lg}) {
        letter-spacing: 0.95px;
        font-size: 1.188rem;
        padding: 10px 28px;
    }
`;

export const HotDealIcon = styled.img`
    width: 139px;
    transform: translateZ(0);
    filter: blur(0);

    @media screen and (${({ theme }) => theme.device.lg}) {
        width: 190px;
    }

    @media screen and (${({ theme }) => theme.device.xl}) {
        width: 260px;
    }
`;

export const PromotionTitle = styled(Title)`
    color: ${({ theme }) => theme.colors.primary.dark};
    font-weight: ${({ theme }) => theme.fontWeight.prompt.bold};
    font-size: 1.438rem;

    @media screen and (${({ theme }) => theme.device.lg}) {
        font-size: 2rem;
        margin-bottom: 10px;
    }
`;

export const OrderTodayText = styled(Paragraph)`
    color: ${({ theme }) => theme.colors.black.main};
    font-weight: ${({ theme }) => theme.fontWeight.prompt.medium};
    font-size: 1.25rem;
    line-height: 1;

    @media screen and (${({ theme }) => theme.device.lg}) {
        font-size: 2rem;
        margin-bottom: 15px;
    }
`;

export const CODText = styled(Paragraph)`
    color: ${({ theme }) => theme.colors.black.main};
    font-weight: ${({ theme }) => theme.fontWeight.prompt.medium};
    font-size: ${({ theme }) => theme.fontSizes.sm};
    line-height: 1;
    margin-bottom: 13px;

    @media screen and (${({ theme }) => theme.device.lg}) {
        font-size: 1.25rem;
        margin-bottom: 24px;
    }
`;

export const HotDealProductCard = styled(Card)`
    padding: 27px;

    @media screen and (${({ theme }) => theme.device.lg}) {
        padding: 37px;
    }
`;

export const HotDealImage = styled.img`
    width: 100%;
    border: 8px solid #fff;
`;

export const HotDealImage2 = styled.img`
    position: absolute;
    left: -18px;
    bottom: -28px;
    z-index: 10;
    width: 100px;
    filter: blur(0);
    transform: translateZ(0);

    @media screen and (min-width: 375px) {
        width: 126px;
        left: -10px;
        bottom: -40px;
    }

    @media screen and (${({ theme }) => theme.device.xs}) {
        width: 160px;
        left: 0;
        bottom: -50px;
    }

    @media screen and (${({ theme }) => theme.device.sm}) {
        bottom: -50px;
        width: 180px;
    }

    @media screen and (${({ theme }) => theme.device.lg}) {
        bottom: -20px;
        width: 220px;
        left: -35px;
    }

    @media screen and (${({ theme }) => theme.device.xl}) {
        width: 270px;
        bottom: 0;
    }
`;

// Instagram

export const InstagramTitle = styled(Title)`
    font-size: ${({ theme }) => theme.fontSizes.lg};
    text-transform: uppercase;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;

    i {
        color: ${({ theme }) => theme.colors.brand.medium};
        font-size: 2.6rem;
    }

    span {
        margin-left: 15px;
    }
`;

export const InstaImageWrap = styled.div`
    padding: 0 15px;
`;

export const ReviewSeparator = styled.div`
    width: 100%;
    height: 5px;
    background-color: ${({ theme }) => theme.colors.brand.dark};
    margin: 35px 0;
`;

// review
export const ReviewSection = styled.section`
    position: relative;
    z-index: 10;
    padding: 15px 0 0 0;
`;

export const ReviewMainTitle = styled(Title)`
    font-size: 1.125rem;
    margin-bottom: 20px;

    @media screen and (${({ theme }) => theme.device.lg}) {
        font-size: 1.563rem;
    }
`;

export const ReviewSliderWrap = styled.div`
    position: relative;
    background-color: #fff;
    padding: 15px 15px 40px 15px;
    border-radius: 30px;
    border: 1px solid ${({ theme }) => theme.colors.brand.dark};
    box-shadow: 0 3px 6px #00000029;
    margin-top: 15px;

    .next {
        color: ${({ theme }) => theme.colors.brand.dark};
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 6%;
        font-size: 36px;
        z-index: 10;
        cursor: pointer;
    }

    .prev {
        color: ${({ theme }) => theme.colors.brand.dark};
        position: absolute;
        left: 6%;
        top: 50%;
        transform: translateY(-50%);
        font-size: 38px;
        z-index: 10;
        cursor: pointer;
    }

    @media screen and (min-width: 375px) {
        width: 90%;
        margin: 15px auto 0 auto;
    }

    @media screen and (${({ theme }) => theme.device.lg}) {
        width: 60%;
        margin: 0 auto;

        .next {
            right: -15%;
        }

        .prev {
            left: -15%;
        }
    }
`;

export const SliderItem = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;

    @media screen and (${({ theme }) => theme.device.lg}) {
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
    }
`;

export const UserImage = styled.img`
    width: 74px;
    height: 74px;
    object-fit: cover;
    border-radius: 50%;
    margin: auto;

    @media screen and (${({ theme }) => theme.device.lg}) {
        width: 115px;
        height: 115px;
    }
`;

export const SliderCol = styled.div`
    @media screen and (${({ theme }) => theme.device.lg}) {
        flex: auto;
    }
`;

export const QuoteWrap = styled.div`
    margin: 25px 0;

    img {
        margin: auto;
    }

    @media screen and (${({ theme }) => theme.device.lg}) {
        margin: 0 0 15px 0;
    }
`;

export const ReviewMoreBtn = styled(Button)`
    text-transform: uppercase;
    position: absolute;
    bottom: -15px;
    right: 10px;
    font-size: ${({ theme }) => theme.fontSizes.md};
    padding: 10px 25px;
`;

export const SliderContentWrap = styled.div``;

export const SliderReviewText = styled(Paragraph)`
    color: ${({ theme }) => theme.colors.black.main};
    font-weight: 600;
    font-size: 0.938rem;
    white-space: pre-line;

    @media screen and (${({ theme }) => theme.device.lg}) {
        font-size: 1.188rem;
    }
`;

export const ReviewImageSeparator = styled.div`
    display: none;

    @media screen and (${({ theme }) => theme.device.lg}) {
        display: block;
        width: 1px;
        background-color: ${({ theme }) => theme.colors.brand.medium};
        height: 90px;
        margin-left: 30px;
        margin-right: 40px;
    }
`;

export const CertificationWrap = styled.section`
    padding: 50px 0;
    position: relative;

    &::after {
        position: absolute;
        content: '';
        height: 110%;
        background-color: ${({ theme }) => theme.colors.brand.medium};
        bottom: 0;
        left: 0;
        z-index: 0;
        width: 100%;
    }
`;

export const CertificationContent = styled.div`
    position: relative;
    z-index: 1;
    text-align: center;

    @media screen and (${({ theme }) => theme.device.lg}) {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: left;
        gap: 29px;
    }
`;

export const CertificationLeft = styled.div`
    text-align: center;
`;

export const CertificationMainTitle = styled(Title)`
    font-family: ${({ theme }) => theme.fontFamily.notoSansKr};
    font-weight: ${({ theme }) => theme.fontWeight.notoSanKr.bold};
    font-size: 2.188rem;
    color: ${({ theme }) => theme.colors.primary.dark};
    margin-bottom: 0;
    line-height: 1.2;

    @media screen and (${({ theme }) => theme.device.lg}) {
        font-size: 2.563rem;
    }
`;

export const CertificationName = styled(Title)`
    font-family: ${({ theme }) => theme.fontFamily.prompt};
    font-weight: ${({ theme }) => theme.fontWeight.prompt.regular};
    font-size: ${({ theme }) => theme.fontSizes.sm};
    color: ${({ theme }) => theme.colors.primary.main};
    margin-bottom: 15px;
    line-height: 1.2;
    text-transform: uppercase;
    letter-spacing: 0.48px;
`;

export const CertificationRightTitle = styled(Title)`
    font-size: 1.188rem;
    letter-spacing: 0.76px;
    color: ${({ theme }) => theme.colors.primary.main};

    @media screen and (${({ theme }) => theme.device.md}) {
        font-size: 1.438rem;
    }
`;

export const CertificateImages = styled.div`
    position: relative;
    z-index: 10;
`;

// signup letter
export const SignUpSection = styled.section`
    padding: 36px 0;
    text-align: center;
    background-color: ${({ theme }) => theme.colors.brand.dark};

    @media screen and (${({ theme }) => theme.device.md}) {
        padding: 50px 0 50px 0;
    }
`;

export const SignupRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    @media screen and (${({ theme }) => theme.device.md}) {
        flex-direction: row-reverse;
    }

    @media screen and (${({ theme }) => theme.device.lg}) {
        width: 90%;
        margin: auto;
    }
`;

export const SignupCol = styled.div`
    flex: 1;
`;

export const SignupLogoWrap = styled.div`
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    margin-bottom: 25px;
`;

export const SignupFormWrap = styled.div`
    padding: 12px 22px 22px 22px;
    background-color: #fff;
    box-shadow: 0 3px 6px #00000029;

    @media screen and (${({ theme }) => theme.device.sm}) {
        width: 80%;
        margin: 0 auto;
    }
`;

export const Form = styled.form`
    margin-top: 15px;
`;

export const FormItem = styled.div`
    margin-bottom: 20px;
`;

export const FormText = styled(Title)`
    color: ${({ theme }) => theme.colors.grey.medium};
    font-size: ${({ theme }) => theme.fontSizes.sm};
    margin-bottom: 15px;
    line-height: 1;
`;

export const SignupFormButton = styled(Button)`
    padding: 10px 30px;
    text-transform: uppercase;
    letter-spacing: 0.4px;
    font-size: 0.5rem;

    @media screen and (${({ theme }) => theme.device.lg}) {
        letter-spacing: 0.6px;
        font-size: 0.75rem;
        padding: 12px 37px;
    }
`;

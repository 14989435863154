import React from 'react';

interface ISliderArrowLeft extends React.HTMLAttributes<HTMLDivElement> {}

export const SliderArrowLeft: React.FC<ISliderArrowLeft> = (props) => {
    const { style, onClick } = props;
    return (
        <div className="prev" style={style} onClick={onClick}>
            <i className="fas fa-chevron-left" onClick={onClick} />
        </div>
    );
};
